@import url('draft-js/dist/Draft.css');

@import '@rainbow-me/rainbowkit/styles.css';

/**
 * Needed so that Rainbowkit dialog can be interacted with when 
 * a Radix UI Dialog is open
 */
[aria-labelledby="rk_connect_title"] {
  @apply pointer-events-auto;
}

/*
 * React Toastify
 */

.Toastify {
  --toastify-toast-min-height: 0;
  --toastify-toast-bd-radius: 0.5rem;
  --toastify-color-success: #2f855a;
  --toastify-color-error: #d51338;
  --toastify-color-info: #7c65c1;
  --toastify-color-light: rgba(0, 0, 0, 0);
  --toastify-toast-width: 382px;
}

@import url('react-toastify/dist/ReactToastify.css');

.Toastify__toast-container--top-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Toastify__toast-container--bottom-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Toastify__toast {
  box-shadow: none;
  padding: 0px 10px;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  padding: 0;
}

.Toastify__toast-theme--colored.Toastify__toast--default
  > .Toastify__toast-body {
  padding: 0;
}

/* End React Toastify */

/*
 * Vidstack Player
 * See https://www.vidstack.io/docs/player/components/layouts/default-layout?styling=default-theme
 */

@import '@vidstack/react/player/styles/default/theme.css';
@import '@vidstack/react/player/styles/default/layouts/audio.css';
@import '@vidstack/react/player/styles/default/layouts/video.css';

.video-player {
  --video-load-button-bg: #000000aa;
  --media-button-size: 26px;
  --media-time-font-size: 13px;
  --video-border-radius: 0;
  --video-border: 0;
  --video-bg: transparent;
}

.vds-video-layout .vds-load-container .vds-play-button:hover {
  background-color: black;
}

:where(.vds-video-layout .vds-load-container) {
  z-index: 5;
}

:where(.vds-video-layout .vds-controls) {
  z-index: 4;
}

:where(.vds-video-layout .vds-controls[data-visible]) {
  background-image: linear-gradient(
    to top,
    rgb(0 0 0 / 0.9),
    20%,
    transparent 40%
  );
}

[data-media-player] {
  display: flex;
}

/* End Vidstack Player */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

/*
  Do not hide scrollbars or shift content left when a dropdown menu is open,
  which is caused by react-remove-scroll adding overflow: hidden and
  margin-right: 15px to the body.
  Relevant discussion: https://github.com/radix-ui/primitives/issues/1496
*/
html body,
html body[data-scroll-locked] {
  --removed-body-scroll-bar-size: 0 !important;
  overflow: initial !important;
  margin-right: 0 !important;
}

@layer components {
  html,
  body,
  #root {
    @apply bg-app text-default;
    height: 100%;
    min-height: 100%;
    touch-action: pan-y;
    font-size: 15px;
    overscroll-behavior: none;
  }

  @media (min-width: theme('screens.sm')) {
    html {
      scrollbar-gutter: stable;
    }
  }

  body {
    @apply decoration-1 underline-offset-2 fade-in;
    @apply text-default;

    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-feature-settings: 'calt' 0;
    -webkit-font-feature-settings: 'calt' 0;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  b,
  strong {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }

  a {
    cursor: pointer;
    @apply text-link;
  }

  #webpack-dev-server-client-overlay {
    /* display: none; */
  }

  .line-clamp-feed {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
  }

  .ms-popup {
    @apply cursor-pointer;
    @apply m-0 ml-5 mt-5 p-0;
    @apply w-[300px];
    @apply max-h-[400px] overflow-scroll overflow-x-hidden;
    @apply rounded-md;
    @apply shadow-md bg-app;
    @apply border border-default;
    @apply z-20;
  }

  .ms-popup-dc {
    @apply !transform-none;
    @apply h-max;
    @apply cursor-pointer;
    @apply !bottom-0 !left-0;
    @apply w-full;
    @apply max-h-[200px] overflow-scroll overflow-x-hidden;
    @apply bg-app;
    @apply border-t border-default;
    @apply z-20;
    box-shadow: inset 0 -5px 7px -5px rgb(0 0 0 / 0.05);
    margin-bottom: calc(var(--dc-editor-height) + 44px);
  }

  .blur-xs {
    --tw-blur: blur(2px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .m {
    text-decoration: none;
    @apply text-link;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* For Webkit-based browsers hide the default button */
  input[type='search']::-webkit-search-cancel-button {
    display: none;
  }

  /* Improve the arrow on select controls to not be as much to the right */
  select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23999999' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }

  @media (prefers-color-scheme: dark) {
    select {
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23AAAAAA' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    }
  }

  /* Webkit + Firefox */
  .only-on-hover-scrollbar:hover {
    /* Avoid content jumping left when scrollbar is shown */
    margin-right: -6px;
  }

  .only-on-hover-scrollbar-horz:hover {
    /* Avoid content jumping down when scrollbar is shown */
    margin-bottom: -6px;
  }
  /* End Webkit + Firefox */

  /*
  Firefox-only part
  Firefox supports only scrollbar-color and scrollbar-width, which make it tricky to only show the scrollbar on hover because content
  shifts.
  Chrome 121+ supports scrollbar-color and scrollbar-width but their presence disables the ::-webkit-scrollbar
  functionality that allows us to dynamically hide the scrollbar.
  The solution is to use scrollbar-color and scrollbar-width only for Firefox, and then show the scrollbar all the time there.
  */
  @-moz-document url-prefix() {
    .only-on-hover-scrollbar,
    .only-on-hover-scrollbar-horz,
    .scrollbar-vert,
    .scrollbar-horz {
      scrollbar-color: #d1d5db99 #fff;
      scrollbar-width: thin;
    }

    .only-on-hover-scrollbar {
      margin-right: -7px;
    }

    .only-on-hover-scrollbar:hover {
      margin-right: -7px;
    }

    .only-on-hover-scrollbar-horz {
      margin-bottom: -6px;
    }

    @media (prefers-color-scheme: dark) {
      .only-on-hover-scrollbar,
      .scrollbar-vert,
      .scrollbar-horz {
        scrollbar-color: #423466 #17101f;
      }
    }
  }
  /* End Firefox-only part */

  /* Webkit-only part */
  .only-on-hover-scrollbar::-webkit-scrollbar {
    display: none;
    width: 6px;
  }

  .only-on-hover-scrollbar-horz::-webkit-scrollbar {
    display: none;
    height: 6px;
  }

  .scrollbar-vert::-webkit-scrollbar {
    width: 6px;
  }

  .scrollbar-horz::-webkit-scrollbar {
    height: 8px;
  }

  .only-on-hover-scrollbar:hover::-webkit-scrollbar,
  .only-on-hover-scrollbar-horz:hover::-webkit-scrollbar {
    display: block !important;
  }

  .only-on-hover-scrollbar::-webkit-scrollbar-thumb,
  .only-on-hover-scrollbar-horz:hover::-webkit-scrollbar-thumb,
  .scrollbar-vert::-webkit-scrollbar-thumb,
  .scrollbar-horz::-webkit-scrollbar-thumb {
    @apply bg-overlay-medium;
    border-radius: 12px;
  }
  /* End Webkit-only part */

  table.table-with-borders {
    @apply mb-3;
  }
  table.table-with-borders td {
    @apply border;
    @apply py-1;
    @apply px-2;
    @apply border-default;
  }

  ul.bullets {
    @apply list-disc;
    @apply pl-4;
  }
}

.HoverCardContent {
  @apply fade-in-slower;
}

@keyframes scrollIntoViewAnimation {
  0% {
    filter: none;
  }
  33% {
    filter: brightness(75%);
  }
  66% {
    filter: brightness(75%);
  }
  100% {
    filter: none;
  }
}

.scroll-into-view-animated {
  animation: scrollIntoViewAnimation 1.2s cubic-bezier(0.17, 0.17, 0, 1);
}

em-emoji-picker {
  height: 300px;
}

.blowfish-ui-content {
  @apply bg-overlay-light;
  @apply rounded-t;
}

.blowfish-ui-content > div {
  padding: 8px;
  gap: 4px;
}

.blowfish-ui-content .jhfYMI {
  align-items: center;
  line-height: 12px;
  gap: 4px;
}

.blowfish-ui-content .jhfYMI svg {
  display: none;
}

.blowfish-ui-content .tQFTe {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.blowfish-ui-content .tQFTe > a .sc-hIjSRL {
  display: flex;
}

div[class*='_actions_'],
div[class*='_replies_'],
div[class*='_authorFollow_'],
a[class*='_infoLink_'] {
  display: none;
}

.react-tweet-theme {
  --tweet-container-margin: 0 !important;
  --tweet-body-font-size: 1rem !important;
  --tweet-border: 1px solid #d0d1d259;
  --tweet-font-color-secondary: #9fa3af;

  max-width: unset !important;
  background: unset !important;
  border: unset !important;
  font-family: unset !important;
  @apply p-3;
}

div[class*='_info_'],
div[class*='-media_root_'] {
  margin-top: 8px;
  @apply max-h-[500px];
}

div[class*='_header_'] {
  @apply !items-center;
  @apply !pb-[8px];
}

div[class*='_author_'] {
  @apply !flex !flex-row !items-center !space-x-1;
}

a[class*='_avatar_'],
svg[class*='_twitterIcon_'] {
  @apply !h-4 !w-4;
}

.react-tweet-theme > article {
  padding: unset !important;
}
